/* *FONTS!  */
@import url("https://fonts.googleapis.com/css2?family=Goldman&family=Inter:wght@300;400;700&family=Wallpoet&display=swap");

/* Change the white to any color */
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #334155 inset !important;
  border-radius: 0px;
  box-shadow: 0 0 0 30px #334155 inset !important;
} */

/*Change text in autofill textbox*/
/* input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
/* ! remove this comment after creating mobile version of app */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  font-family: "Inter", sans-serif;
  @apply bg-slate-50 dark:bg-slate-900;
}

/* UI STYLES */
button {
  @apply duration-75 ease-in active:translate-y-0.5 active:scale-[0.95] active:opacity-90;
}

th {
  @apply font-normal;
}

.bordered {
  @apply border border-gray-300;
}

.rounded {
  @apply rounded-lg;
}

.shadowed {
  @apply shadow-md shadow-gray-300 dark:shadow-slate-900;
}

.form-element {
  @apply rounded px-3 py-2 text-sm;
}

.gaped {
  @apply gap-2;
}

.card {
  @apply shadowed text-main mb-5 rounded bg-white p-5 dark:bg-slate-800;
}
/*TYPOGRAPHY*/
.text-primary {
  @apply text-indigo-600 dark:text-indigo-500;
}
.bg-primary {
  @apply !bg-indigo-600 text-white dark:!bg-indigo-800 dark:text-gray-300;
}
.accent-primary {
  @apply accent-indigo-600 dark:accent-indigo-600;
}
.hover\:text-primary:hover {
  @apply text-indigo-600;
}

.text-green {
  @apply text-emerald-600;
}
.bg-green {
  @apply !bg-emerald-600 text-white dark:!bg-emerald-800 dark:text-gray-300;
}
.accent-green {
  @apply accent-emerald-600;
}

.text-red {
  @apply text-rose-600 dark:text-rose-700;
}
.bg-red {
  @apply !bg-rose-600 text-white dark:!bg-rose-700;
}
.accent-red {
  @apply accent-rose-600;
}

.text-yellow {
  @apply text-yellow-600 dark:text-yellow-600;
}
.bg-yellow {
  @apply !bg-yellow-500 !text-slate-600 dark:!bg-yellow-600 dark:!text-slate-800;
}
.accent-yellow {
  @apply accent-yellow-600;
}

.text-main {
  @apply text-gray-500 dark:text-gray-400;
}
.text-dark {
  @apply text-gray-700 dark:text-gray-300;
}
.bg-main {
  @apply text-dark !bg-gray-500;
}

.text-disabled {
  @apply text-gray-400;
}
.bg-disabled {
  @apply !bg-gray-300 text-white;
}
.accent-disabled {
  @apply accent-gray-300;
}

.bg-gradient {
  background: linear-gradient(19deg, #000000, #008cff, #00ff20);
  background-size: 1000% 1000%;
  -webkit-animation: Gradient 21s ease infinite;
  -moz-animation: Gradient 21s ease infinite;
  -o-animation: Gradient 21s ease infinite;
  animation: Gradient 21s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 63%;
  }

  50% {
    background-position: 100% 38%;
  }

  100% {
    background-position: 0% 63%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 63%;
  }

  50% {
    background-position: 100% 38%;
  }

  100% {
    background-position: 0% 63%;
  }
}

@-o-keyframes Gradient {
  0% {
    background-position: 0% 63%;
  }

  50% {
    background-position: 100% 38%;
  }

  100% {
    background-position: 0% 63%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 63%;
  }

  50% {
    background-position: 100% 38%;
  }

  100% {
    background-position: 0% 63%;
  }
}
